import React, {Fragment, useEffect, useRef, useState} from 'react';

import Layout from "../../templates/layout";
import BasePageContent from "../../components/utils/BasePageContent";
import {connect} from "react-redux";
import * as actions from '../../actions';
import {bindActionCreators} from "redux";
import {
	Alert,
	Box,
	Button,
	Grid, Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import StyledTableCell from "../../components/utils/StyledTableCell";
import Paginator from "../../components/Paginator";
import {navigate} from "gatsby";
import PayerDataDialog from "../../components/payers/PayerDataDialog";

const mapStateToProps = (state) => ({
	payers: state.getPayers,
	createdPayer: state.createPayer,
});

const ValueFallback = () => <i style={{color: 'lightgrey'}}>nie wskazano</i>;

const PayerCard = ({data}) => (
		<Paper
			onClick={() => navigate(`/payers/${data.code}`)}
			variant="outlined"
			sx={{my: 2, cursor: 'pointer'}}
		>
			<Box p={2}>
				<Grid container spacing={2} justifyContent="space-between">
					{[
						['Email', data.email],
						['Imię i nazwisko', data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : <ValueFallback/>],
						['Nazwa', data.name || <ValueFallback/>],
						['Numer telefonu', data.phoneNumber ? `${data.phonePrefix} ${data.phoneNumber}` : <ValueFallback/>],
						['Utworzono', data.createdAt],
					].map(([key, value]) => (
						<Grid
							key={key}
							container
							item
							xs={12}
							alignItems="center"
							sx={{borderTop: '1px solid #e3e3e3'}}
						>
							<Grid item xs={5} sx={{textTransform: 'uppercase'}}>
								<b>{key}</b>
							</Grid>
							<Grid item xs={6} sx={{wordWrap: 'break-word'}}>
								<Box textAlign="right">{value}</Box>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Box>
		</Paper>
	);

const TableCellWithFallback = ({value}) => (
	<TableCell>{value || <ValueFallback/>}</TableCell>
)

const Payers = ({payers, actions, createdPayer}) => {
	const [loading, setLoading] = useState(true);
	const [createDialogOpen, setCreateDialogOpen] = useState(false);
	const createdPayerCodeRef = useRef(createdPayer?.data?.code || null);

	useEffect(() => {
		if (payers) setLoading(false);
	}, [payers]);

	useEffect(() => {
		if (createdPayer && createdPayer.status !== 'ERROR') {
			setCreateDialogOpen(false)
			if (createdPayerCodeRef.current !== createdPayer?.data.code) {
				navigate(createdPayer?.data.code);
			}
		}
	}, [createdPayer]);

	useEffect(() => actions.getPayers(), [actions]);

	const handleCreateNewPayer = (data) => {
		// setLoading(true);

		actions.createPayer(data)
	}

	return <Layout>
		<BasePageContent loading={loading}>
			<Box my={2}>
				<Paginator
					resource={payers}
					getResource={pagination => actions.getPayers(pagination)}
					render={(payers, renderPagination, loading) => (
						<>
							{!loading && (
								<>
									<Box mb={2}>
										<Button
											variant="contained"
											disableElevation
											onClick={() => setCreateDialogOpen(true)}
										>
											Dodaj płatnika
										</Button>
									</Box>
									<PayerDataDialog
										loading={loading}
										errors={createdPayer?.status === 'ERROR' ? createdPayer.details : {}}
										dialogOpen={createDialogOpen}
										handleSubmit={handleCreateNewPayer}
										closeDialog={() => setCreateDialogOpen(false)}
									/>
									{payers?.length ? (
										<TableContainer>
											<Box
												sx={{display: {xs: 'block', md: 'none'}}}
											>
												{payers.map(data => <PayerCard key={data.code} data={data}/>)}
											</Box>
											<Table sx={{display: {xs: 'none', md: 'table'}}}>
												<TableHead>
													<TableRow>
														<StyledTableCell>Imię</StyledTableCell>
														<StyledTableCell>Nazwisko</StyledTableCell>
														<StyledTableCell>Nazwa</StyledTableCell>
														<StyledTableCell>Email</StyledTableCell>
														<StyledTableCell>Telefon</StyledTableCell>
														<StyledTableCell>Utworzono</StyledTableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{payers.map(p => (
														<TableRow hover key={p.code} onClick={() => navigate(p.code)} sx={{cursor: 'pointer'}}>
															<TableCellWithFallback value={p.firstName}/>
															<TableCellWithFallback value={p.lastName}/>
															<TableCellWithFallback value={p.name}/>
															<TableCellWithFallback value={p.email}/>
															<TableCellWithFallback value={p.phoneNumber ? `${p.phonePrefix} ${p.phoneNumber}` : ''}/>
															<TableCellWithFallback value={p.createdAt}/>
														</TableRow>
													))}
												</TableBody>
											</Table>
											<Grid container justifyContent="center">
												<Grid item>
													<Box py={2}>{renderPagination()}</Box>
												</Grid>
											</Grid>
										</TableContainer>
									) : <Alert severity="info">Nie utworzono jeszcze żadnego płatnika</Alert>}
								</>)}
						</>
					)}
				/>
			</Box>
		</BasePageContent>
	</Layout>
}

export const Head = () => <title>Płatnicy | Zapłatomat</title>;


const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(Payers);
